<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="600"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text
                v-if="loading"
            >
                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{ $t("Загрузка...") }}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>
            <v-card-text
                v-else
                class="wrapperFormModal"
            >
                <v-card flat>
                    <v-card-text>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("Регистрировать_как_ГУ") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-switch
                                    v-model="registerAsGovService"
                                    inset
                                    hide-details
                                    class="cust-switch"
                                />    
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("Журналы_регистрации") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-select
                                    v-model="prevYear"
                                    :items="journals"
                                    item-value="id"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("Параметры_номера") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                  
                                <v-radio-group
                                    v-model="fromReserv"
                                    dense
                                    mandatory
                                    hide-details
                                >
                                    
                                    <v-radio
                                        :value="false"
                                    >
                                        <template v-slot:label>
                                            <v-col
                                                cols="4" sm="4" md="4"
                                                class="ma-0 pa-0"
                                            >
                                                {{ $t('По_порядку') }}
                                            </v-col>
                                            <v-col
                                                cols="8" sm="8" md="8"
                                                class="ma-0 pa-0"
                                            >
                                                <div
                                                    v-if="!fromReserv"
                                                >
                                                    {{ regNumber }}
                                                </div>
                                            </v-col>
                                        </template>
                                    </v-radio>
                                
                                    <v-radio
                                        :value="true"
                                        v-if="reserveItems.length > 0"
                                    >
                                        <template v-slot:label>
                                            <v-col
                                                cols="4" sm="4" md="4"
                                                class="ma-0 pa-0"
                                            >
                                                {{ $t('Из_резерва') }}
                                            </v-col>
                                            <v-col
                                                cols="8" sm="8" md="8"
                                                class="ma-0 pa-0"
                                            >
                                                <v-select
                                                    v-if="fromReserv"
                                                    v-model="reserve"
                                                    :items="reserveItems"
                                                    item-text="Template"
                                                    return-object
                                                    hide-details
                                                    outlined
                                                    dense
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                ></v-select>
                                            </v-col>
                                        </template>
                                    </v-radio>
                                
                                </v-radio-group>
                                                        
                            </v-col>
                        </v-row>

                        <v-row no-gutters v-if="counterValid && registerAsGovService == false">
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("Уникальный_номер") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-radio-group
                                    v-model="uniqueNumberParam"
                                    dense
                                    mandatory
                                    hide-details
                                >
                                    
                                    <v-radio
                                        :label="$t('Без_номера')"
                                        :value="0"
                                    >
                                    </v-radio>
                                                               
                                    <v-radio
                                        :label="$t('Номер_по_порядку')"
                                        :value="1"
                                    ></v-radio>
                                                                
                                    <v-radio
                                        :value="2"
                                    >
                                        <template v-slot:label>
                                            <v-col
                                                cols="4" sm="4" md="4"
                                                class="ma-0 pa-0"
                                            >
                                                {{ $t('Почтовый_номер') }}
                                            </v-col>
                                            <v-col
                                                cols="8" sm="8" md="8"
                                                class="ma-0 pa-0"
                                            >
                                                <v-text-field
                                                    v-if="uniqueNumberParam === 2"
                                                    v-model.trim="postUniqueNumber"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    maxlength="13"
                                                ></v-text-field>
                                            </v-col>
                                        </template>
                                    </v-radio>
                                
                                </v-radio-group>
                                
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="!loading && valid"
                >
                    {{ $t("Зарегистрировать") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "RegisterEuolStatementDlg",
    data () {
        return {
            title: "Регистрация",
            journals:
            [
                { id: false, Value: this.$t("Журнал_текущего_года") },
                { id: true, Value: this.$t("Журнал_предыдущего_года") }
            ]
        }
    },
    computed: {
        ...mapGetters('dialogs/registerEuolStatementPrimary',
        { 
            visible: 'isVisible',
            loading: 'isLoading',
            canRegisterAsGovService: 'canRegisterAsGovService',
            reserveItems: 'getReserveItems',
            regNumber: 'getRegNumber',
            valid: 'isValid',
            counterValid: 'isCounterValid'
        }),
        registerAsGovService: {
            get: function() {
                return this.$store.getters['dialogs/registerEuolStatementPrimary/isRegisterAsGovService'];
            },
            set: function(v) {
                this.$store.dispatch('dialogs/registerEuolStatementPrimary/onRegisterAsGovServiceChange', v);
            }
        },
        prevYear: {
            get: function() {
                return this.$store.getters['dialogs/registerEuolStatementPrimary/isPrevYear'];
            },
            set: function(v) {
                this.$store.dispatch('dialogs/registerEuolStatementPrimary/onPrevYearChange', v);
            }
        },
        fromReserv:  {
            get: function() {
                return this.$store.getters['dialogs/registerEuolStatementPrimary/isFromReserve'];
            },
            set: function(value) {
                this.$store.commit('dialogs/registerEuolStatementPrimary/UPDATE_CURRENT_PARAMS', { property: "FromReserv", value });
            }
        },
        reserve: {
            get: function() {
                return this.$store.getters['dialogs/registerEuolStatementPrimary/getReserveItem'];
            },
            set: function(value) {
                this.$store.commit('dialogs/registerEuolStatementPrimary/UPDATE_CURRENT_PARAMS', { property: "ReserveItem", value });
            }
        },
        uniqueNumberParam: {
            get: function() {
                return this.$store.getters['dialogs/registerEuolStatementPrimary/getUniqueNumberParam'];
            },
            set: function(value)
            {
                switch (value)
                {
                    case 0:
                        this.$store.commit('dialogs/registerEuolStatementPrimary/UPDATE_CURRENT_PARAMS', { property: "NeedUniqueNumber", value: false });
                        this.$store.commit('dialogs/registerEuolStatementPrimary/UPDATE_CURRENT_PARAMS', { property: "PostUniqueNumber", value: null });
                        break;
                    
                    case 1:
                        this.$store.commit('dialogs/registerEuolStatementPrimary/UPDATE_CURRENT_PARAMS', { property: "NeedUniqueNumber", value: true });
                        this.$store.commit('dialogs/registerEuolStatementPrimary/UPDATE_CURRENT_PARAMS', { property: "PostUniqueNumber", value: null });
                        break;

                    case 2:
                        this.$store.commit('dialogs/registerEuolStatementPrimary/UPDATE_CURRENT_PARAMS', { property: "NeedUniqueNumber", value: true });
                        this.$store.commit('dialogs/registerEuolStatementPrimary/UPDATE_CURRENT_PARAMS', { property: "PostUniqueNumber", value: "" });
                        break;
                }
            }
        },
        postUniqueNumber: {
            get: function() {
                return this.$store.getters['dialogs/registerEuolStatementPrimary/getPostUniqueNumber'];
            },
            set: function(value) {
                this.$store.commit('dialogs/registerEuolStatementPrimary/UPDATE_CURRENT_PARAMS', { property: "PostUniqueNumber", value });
            }
        }
    },
    methods: {
        ...mapActions('dialogs/registerEuolStatementPrimary', ['ok', 'cancel'])
    }
}
</script>